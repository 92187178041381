import { EditorReadyFn } from 'yoshi-flow-editor-runtime';
import { createAppAPI } from './editor/services/app-api';
import {
  initExperiments,
  isADIHorizontalEnabled,
} from './editor/services/experiments';
import {
  getLayoutType,
  maybeSetHorizontalLayout,
} from './editor/services/styles';
import { initMonitoring, toMonitored } from './editor/services/monitoring';
import * as publicApi from './editor/services/public-api';
import { EditorSDK } from './editor/constants/types';

let editorSDK: EditorSDK;
let appDefId: string;

const editorReady: EditorReadyFn = async (
  _editorSDK,
  appDefinitionId,
  options,
  flowApi,
) => {
  editorSDK = _editorSDK;
  appDefId = appDefinitionId;
  await initExperiments(flowApi);
  await initMonitoring(editorSDK, options, flowApi);
  await editorSDK.editor.setAppAPI('', createAppAPI(editorSDK));

  if (!options.firstInstall) {
    return;
  }

  const allowHorizontalInADI = await isADIHorizontalEnabled();
  const isADI = options.origin.type.includes('ADI');
  const isHorizontalLayout = !isADI || allowHorizontalInADI;

  if (isHorizontalLayout) {
    await toMonitored('setHorizontalLayout', () =>
      maybeSetHorizontalLayout(editorSDK, appDefId),
    );
  }
};

const _exports = {
  isHorizontalLayout: () => getLayoutType(editorSDK, appDefId),
  refreshApp: () => publicApi.refreshApp(editorSDK),
  setBooleanStyleParamForWidgets: (key: string, value: boolean) =>
    publicApi.setBooleanStyleParamForWidgets(editorSDK, appDefId, key, value),
};

export { editorReady, _exports as exports };
